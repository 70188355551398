<template>
    <b-card>
        <b-row>
            <b-col cols="9">
                <b-button class="m-1 " v-ripple.400="'#1396EB'" variant="primary" v-b-modal.modal-primaryadd>
                    Добавление
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-select class="m-1" v-model="defaultLang" :options="langs" text-field="name" value-field="code"
                    @change="getStatis" />
            </b-col>
        </b-row>
        <b-modal @ok="add" @hidden="resetModal" id="modal-primaryadd" ok-only ok-title="Добавить" modal-class="modal-primary" centered
            title="Добавление">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="statis.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="statis.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="statis.descriptions" id="textarea-default" placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                            <b-form-group>
                                <label for="count" class="text-nowrap">Оценка</label>
                                <b-form-select v-model="statis.count" :options="counts"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Язык" label-for="langs">
                    <b-form-select v-model="statis.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                            <b-form-group>
                                <label for="selected" class="text-nowrap">Статистика для страницы о нас?</label>
                                <b-form-select v-model="statis.is_for_about_us" :options="options"/>
                            </b-form-group>
                        </b-col>
            </b-row>
        </b-modal>
        <b-table :fields="tableColumns" responsive="sm" :items="statistic">
            <template #cell(img)="data">
            <b-avatar v-if="data.item.img" class="mr-1" :src="data.item.img" />
       </template>
       <template #cell(is_for_about_us)="data">
          <div class="text-nowrap">
          {{data.item.is_for_about_us == 1 ? 'Для главной страницы' : data.item.is_for_about_us  == 0 ? 'Для страницы о нас' : ''}}
          </div>
        </template>
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-button class="btn-icon mr-1" variant="gradient-primary" id="gradient-primaryy"
                        @click="edit(data.item)" v-b-modal.modal-primaryedit>
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </div>
            </template>
        </b-table>
                <b-modal @hidden="resetModal" @ok="handleEdit" id="modal-primaryedit" ok-only ok-title="Редактировать" modal-class="modal-primary" centered
            title="Редактирование">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="statis.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="statis.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="statis.descriptions" id="textarea-default" placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                            <b-form-group>
                                <label for="count" class="text-nowrap">Оценка</label>
                                <b-form-select v-model="statis.count" :options="counts"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Язык" label-for="langs">
                    <b-form-select v-model="statis.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                            <b-form-group>
                                <label for="selected" class="text-nowrap">Статистика для страницы о нас?</label>
                                <b-form-select v-model="statis.is_for_about_us" :options="options"/>
                            </b-form-group>
                        </b-col>
            </b-row>
        </b-modal>
    </b-card>
  </template>
  
  <script>
  import {BAvatar, BFormSelect, BFormFile, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import axios from 'axios'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  export default {
    components: {
        BAvatar,
        BFormSelect,
        BFormFile,
        BFormTextarea,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BModal,
        VBModal,
        BCard,
        BButton,
        BTable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {
  
        return {
            statis:{
                title: '',
                file: [],
                descriptions: "",
                count: "",
                is_for_about_us: '',
                lang: ''
            },
            statistic:[],
            langs: [],
            defaultLang: 'ru',
            options: [
                { value: null, text: 'Выберите одну' },
                { value: '1', text: 'Да' },
                { value: '0', text: 'Нет' },
               
            ],
            counts: [
                { value: null, text: 'Выберите оценку' },
                { value: '1', text: '1' },
                { value: '2', text: '2' },
                { value: '3', text: '3' },
                { value: '4', text: '4' },
                { value: '5', text: '5' },
               
            ],
            tableColumns: [
                {
                    key: 'id',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'title',
                    label: 'Название',
                    sortable: true,
                },
                {
                    key: 'img',
                    label: 'Изображение',
                    sortable: true,
                },
                // {
                //     key: 'description',
                //     label: 'Описание',
                //     sortable: true,
                // },
                // {
                //     key: 'count',
                //     label: 'Оценка',
                //     sortable: true,
                // },
                // {
                //     key: 'is_for_about_us',
                //     label: 'Статистика для страницы о нас?',
                //     sortable: true,
                // },
                
                {
                    key: 'actions',
                    label: 'Действия',
                }]
        }
    },
    mounted() {
        this.getStatis()
        this.getLangs()
    },
    methods: {
        getLangs() {
            axios.get(`${$themeConfig.app.API}langs`)
                .then(res => {
                    this.langs = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },

        getStatis() {
            const getStatis = {
                method: 'get',
                url: `${$themeConfig.app.API}statistic`,
                headers: {
                    'Accept-Language': this.defaultLang
                }
            };
            axios(getStatis)
                .then(res => {
                    this.statistic = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        async add() {
            const myFormData = new FormData()
            myFormData.append('title', this.statis.title)
            myFormData.append('img', this.statis.file)
            myFormData.append('description', this.statis.descriptions)
            myFormData.append('lang_id', this.statis.lang)
            myFormData.append('count', this.statis.count)
            myFormData.append('is_for_about_us', this.statis.is_for_about_us)


            await axios({
                method: 'post',
                url: `${$themeConfig.app.API}statistic`,
                data: myFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(() => {
                    this.addStatus = true
                    this.getStatis()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Успешно',
                            text: 'Запись успешно добавлен',
                            icon: 'CheckSquareIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    this.addStatus = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ошибка',
                            text: e.message,
                            icon: 'CheckSquareIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        resetModal() {
            this.statis.title = ''
            this.statis.id = ''
            this.statis.lang = ''
            this.statis.file = ''
            this.statis.descriptions = ''
            this.statis.count = ''
            this.statis.is_for_about_us = ''
        },
        async handleEdit() {
            const myFormData = new FormData()
            myFormData.append('title', this.statis.title)
            myFormData.append('img', this.statis.file)
            myFormData.append('description', this.statis.descriptions)
            myFormData.append('lang_id', this.statis.lang)
            myFormData.append('count', this.statis.count)
            myFormData.append('is_for_about_us', this.statis.is_for_about_us)
            myFormData.append('_method', 'PUT')

            if (this.statis.id !== '') {
                await axios({
                    method: 'post',
                    url: `${$themeConfig.app.API}statistic/${this.statis.id}`,
                    data: myFormData,
                    config: {},
                })
                    .then(() => {
                        this.getStatis()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Успешно',
                                text: 'Данные были изменены',
                                icon: 'CheckSquareIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ошибка',
                                text: e.message,
                                icon: 'CheckSquareIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        edit(data) {
            this.statis.title = data.title
            this.statis.id = data.id
            this.statis.lang = data.lang.id
            this.statis.file = data.img
            this.statis.descriptions = data.description
            this.statis.count = data.count
            this.statis.is_for_about_us = data.is_for_about_us
        },
        confirmDelete(data) {
            this.$bvModal
                .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
                    title: 'Вы уверены что хотите удалить?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отменить',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        axios.delete(`${$themeConfig.app.API}statistic/${data.id}`)
                            .then(() => {
                                this.getStatis()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Успешно',
                                        text: 'Данные были удалены',
                                        icon: 'CheckSquareIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Ошибка',
                                        text: e.message,
                                        icon: 'CheckSquareIcon',
                                        variant: 'dander',
                                    },
                                })
                            })
                    }
                })
        },
    }
  }
  </script>